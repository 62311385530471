/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import Newsletter from "../components/Newsletter";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

import data_default from "./default/data";
import data_metaverseme from "./metaverse_me/data";
import data_netherak from "./netherak/data"

const CaseStudy = () => {
  
  const the_case  = useParams();

  const [lightboxDisplay, setLightBoxDisplay] = useState(false);
  const [imageToShow, setImageToShow] = useState(0);

  let the_data = data_default;
  switch (the_case.study) {
    case 'metaverse-me':
      the_data = data_metaverseme
      break;
    case 'netherak':
      the_data = data_netherak
      break;
    default:
      the_data = data_default
      break;
  }
  
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      slidesToSlide: 3 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      slidesToSlide: 2 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const showImage = (imgId) => {
      //set imageToShow to be the one that's been clicked on    
      setImageToShow(imgId);
      //set lightbox visibility to true
      setLightBoxDisplay(true);
  };

  return (
    <>
      <div className="container mx-auto px-10 pb-0">
        <section>
          <div className="grid grid-cols-1 sm:grid-cols-2 place-items-start">
            <div className="p-10 sm:order-last">
              <img className="rounded-2xl border-2 border-fvyellow" src={the_data.main_image} alt="laos case study" />
            </div>
            <div className="pt-10 text-center sm:text-left">
              <div className="flex flex-col">
                <div className="text-fvyellow text-md font-sansation">
                  {the_data.release}
                </div>
                <div className="text-white text-4xl font-sansation mb-2">
                {the_data.title}
                </div>
                <div className="text-2xl">
                {the_data.text}
                </div>
                <div className="mb-10 mt-10">
                <a
                  href={the_data.button_link}
                  className="border bg-fvyellow border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                >
                  {the_data.button_text}
                </a>
                </div>
              </div>
            </div>
          </div>
        </section>

      </div>
      <div className="bg-[#E6E6E6] text-[#52337D] text-fvpurple">
        <div className="grid grid-cols-1 sm:grid-cols-3 py-4 place-items-center">
          <div className="flex flex-row items-end space-x-2">
            <div className="text-lg">Developer</div>
            <div className="text-2xl font-bold">{the_data.developer}</div>
          </div>
          <div className="flex flex-row items-end space-x-2">
            <div className="text-lg">Genre</div>
            <div className="text-2xl font-bold">{the_data.genre}</div>
          </div>
          <div className="flex flex-row items-end space-x-2">
            <div className="text-lg">OS</div>
            <div className="text-2xl font-bold">{the_data.OS}</div> 
          </div>
        </div>
      </div>
      <section className="w-full bg-white">

          <Carousel
            showDots={true}
            responsive={responsive}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            containerClass="carousel-container"
            dotListClass="custom-dot-list-style"
            itemClass="carousel-item-padding-40-px"
            >
            {the_data.slider_images.map((item, index) => (
              <div key={index} className="p-10">
                <img className="rounded-2xl border-2 border-fvyellow" onClick={()=> showImage(index)} src={item} alt="logo" />
              </div>
            ))}
          </Carousel>
      </section>
      <div className="pt-12 bg-white text-[#707070]">
        <div className="container mx-auto px-10 pb-12">
            <div className="flex flex-col">
              <div className="font-sansation text-fvpurple text-4xl text-center">{the_data.body_title}</div>
              {the_data.body_text_paras.map((item, index) => (
                <div key={index} className="pt-12 text-[#707070] text-lg">
                  {item}
                </div>
              ))} 
              <div className="mb-10 mt-10 text-center">
                <a
                  href={the_data.button_link}
                  className="border bg-fvyellow border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                >
                  {the_data.button_text}
                </a>
                </div>
            </div>
        </div>
      </div>

      {/* Signup */}
      <Newsletter />
      {/* Lightbox */}
      { lightboxDisplay ?
        <div
          onClick={()=> setLightBoxDisplay(false)} 
          className="z-[2000] fixed top-0 left-auto w-full h-full flex items-center justify-center">
          <img alt="focus" className="rounded-2xl border-2 border-fvyellow" src={the_data.slider_images[imageToShow]}></img>
        </div>
      : '' }
    </>
  );
};

export default CaseStudy;
