import React, { useState } from 'react';
import MenuDropdownMobile from './MenuDropdownMobile';
import ArrowDown from '../components/ArrowDown';
import { NetworkMenu, BuildMenu, CommunityMenu, AboutMenu }  from './MenuContents'

const MenuMobile = () => {
  const [showBuildMenu, setShowBuildMenu] = useState(false);
  const [showNetworkMenu, setShowNetworkMenu] = useState(false);
  const [showCommunityMenu, setShowCommunityMenu] = useState(false);
  const [showAboutMenu, setShowAboutMenu] = useState(false);

    return (
        <>
        <div className="text-white transition duration-300 ease-in-out block py-2">
          <div className="inline" onClick={() => setShowBuildMenu(!showBuildMenu)}>
            Build <ArrowDown />
          </div>
          <div className={`menu-resources-mobile ${showBuildMenu ? "" : "hidden"}`}>
            <MenuDropdownMobile contents={BuildMenu}/>  
          </div>
        </div>
        <div className="text-white transition duration-300 ease-in-out block py-2">
          <div className="inline" onClick={() => setShowNetworkMenu(!showNetworkMenu)}>
            Network <ArrowDown />
          </div>
          <div className={`menu-resources-mobile ${showNetworkMenu ? "" : "hidden"}`}>
            <MenuDropdownMobile contents={NetworkMenu}/>  
          </div>
        </div>
        <div className="text-white transition duration-300 ease-in-out block py-2">
          <div className="inline" onClick={() => setShowCommunityMenu(!showCommunityMenu)}>
            Community <ArrowDown />
          </div>
          <div className={`menu-resources-mobile ${showCommunityMenu ? "" : "hidden"}`}>
            <MenuDropdownMobile contents={CommunityMenu}/>  
          </div>
        </div>
        <div className="text-white transition duration-300 ease-in-out block py-2">
          <div className="inline" onClick={() => setShowAboutMenu(!showAboutMenu)}>
            About <ArrowDown />
          </div>
          <div className={`menu-resources-mobile ${showAboutMenu ? "" : "hidden"}`}>
            <MenuDropdownMobile contents={AboutMenu}/>  
          </div>
        </div>





        <a
          href="/#community"
          className="border border-fvyellow bg-fvyellow text-fvpurple px-4 py-2 rounded transition duration-300 ease-in-out block py-2 hover:bg-transparent hover:text-fvyellow"
        >
          Join Community
        </a>
      </>
    );
};

export default MenuMobile;