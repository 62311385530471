/* eslint-disable react/prop-types */
import React from "react";
import { Tab, Tabs } from "./Tabs"
import img_usecasesgames from "../../img/use_cases_games.png";
import img_usecasesRWA from "../../img/use_cases_RWA.png";
import img_usecasesNFT from "../../img/use_cases_NFT.png";
import img_usecasesBitcoin from "../../img/use_cases_bitcoin.png"

const TitleGames = "Games"
const ParaGames = "LAOS Network’s Bridgeless Minting allows you to create unlimited dynamic assets in your games, tradable in the EVM chain of your choice."
const BulletsGames = [
    "Gift assets to all of your players, without worrying about gas fees or network congestion.",
    "Allow gamers to upgrade their assets through game-mechanics.",
    "Connect seamlessly to the major marketplaces of your chosen EVM chain, and use the wallet of your choice."
]

const TitleRWA = "RWA Tokenization"
const ParaRWA = "Open up new cases for tokenization of RWAs at massive scale - tradable in the mature ecosystems, where liquidity lies. "
const BulletsRWA = [
    "Tokenize millions of assets in Ethereum, without paying ETH, and without risking congestion.",
    "Extend RWA tokenization beyond high-value assets.",
    "Connect tokenized assets to mature liquidity pools, lending, and DeFi."
]

const TitleBitcoin = "Bitcoin NFTs"
const ParaBitcoin = "LAOS Bridgeless Minting goes beyond Inscriptions and Runes to create Non-Fungible Tokens on Bitcoin without depending on centralized indexers."
const BulletsBitcoin = [
    "Massively scale Bitcoin’s minting throughput at a fraction of the current cost",
    "Create dynamic NFTs on Bitcoin, with data 100% on-chain",
    "Trade created items on Bitcoin's Layer-1"
]

const TitleNFT = "NFTs"
const ParaNFT = "From collectibles to ticketing, minting NFTs with LAOS virtually eliminates gas costs, while permitting tokens to be tradable in the EVM marketplaces of your choice, from Day 1."
const BulletsNFT = [
    "Create a collection and sell the assets on Opensea or any other EVM marketplace.",
    "Users can import their NFTs into Metamask and other compatible wallets.",
    "Use our uERC-721 contract to mint on the EVM chain of your choice. "
]



const UseCase = ({title, paragraph, bullets, image}) => {
    return (
        <>
        <div className="grid lg:grid-cols-5">
            <div className="lg:col-span-3 lg:pr-4 order-last lg:order-first">
                <h3 className="text-2xl lg:text-4xl font-bold py-6">{title}</h3>
                <div className="text-lg py-4">{paragraph}</div>
                <ul className="text-lg space-y-4 list-disc list-inside">
                {bullets && bullets.map((item, i) => {
                    return (
                        <li className="ml-4" key={i}>{item}</li>
                    )
                })}
                </ul>
                <div className="mt-10 flex justify-center lg:justify-start"><a
                    href="https://docs.laosnetwork.io/"
                    className="border bg-fvyellow border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                >
                    Start Building
                </a></div>
            </div>
            <div className="flex lg:col-span-2 justify-center">
                <img
                    className="self-start"
                    src={image}
                    alt="Games"
                />
            </div>
        </div>
        
        </>
    )
}

const UseCases = () => {
    return (
        <>
        <Tabs>
            <Tab
            component={<UseCase
                title={TitleGames}
                paragraph={ParaGames}
                bullets={BulletsGames}
                image={img_usecasesgames}
                />} active>Games</Tab>
            <Tab component={<UseCase
                title={TitleRWA}
                paragraph={ParaRWA}
                bullets={BulletsRWA}
                image={img_usecasesRWA}
                />}>RWA</Tab>
            <Tab component={<UseCase
                title={TitleBitcoin}
                paragraph={ParaBitcoin}
                bullets={BulletsBitcoin}
                image={img_usecasesBitcoin}
                />}>Bitcoin</Tab>
            <Tab component={<UseCase
                title={TitleNFT}
                paragraph={ParaNFT}
                bullets={BulletsNFT}
                image={img_usecasesNFT}
                />}>NFT</Tab>
        </Tabs>
        </>
    )
}
export default UseCases;