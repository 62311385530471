/* eslint-disable react/prop-types */

import React, { useState } from 'react';
import ArrowDown from './ArrowDown';

const DeveloperFAQ = (props) => {

    const [open, setOpen] = useState(0)

    return (
    <div className='border border-fvpurple rounded py-4 px-8 mb-4'>
        <div className="flex flex-col" onClick={()=>setOpen(!open)}>
            <div className="flex items-center justify-between">
                <div className="font-bold text-xl text-fvorange">
                    {props.title} 
                </div>
                <div className="">
                    <ArrowDown color="#E3976D" /> 
                </div>
            </div>
            <div className={open ? 'block' : 'hidden'}>
                {props.text} 
            </div> 
        </div>
        
        
    </div>
    );
};

export default DeveloperFAQ;