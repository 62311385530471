/* eslint-disable react/prop-types */ 
import React from 'react';
import LinkPanelArrow from './components/LinkPanelArrow';
import DeveloperFAQ from './components/DeveloperFAQ';
import Newsletter from './components/Newsletter';
import hero_image from '../img/GRANTS_IMAGE_HERO.png';
import image_190 from '../img/190M_v2.png';
import image_coins from '../img/COINS_MILESTONES.png';
import image_arrow from '../img/GROWTH.png';
import image_rocket from '../img/ROCKET.png';

const CONTENT = {
    link_arrows: [
        {
            title: "Grant Application Template",
            link: "/downloads/LAOS_Grant_Application_Form.docx" 
        },
        {
            title: "Sample Grant Application",
            link: "/downloads/LAOS_Grant_Application_Form_Sample.pdf"
        },
        {
            title: "Apply Now",
            link: "https://form.typeform.com/to/A6WvByOu"
        },
        {
            title: "LAOS Tokenomics",
            link: "https://laos.gitbook.io/laos-whitepaper/tokenomics"
        },
        {
            title: "Builder's Guide",
            link: "/build"
        },
        {
            title: "Join Our Community",
            link: "/community"
        },
    ],
    faq: [
        {
            title:"How much is a typical grant?",
            text: "The amount for each grant varies based on each project's potential impact. Typical awards are in the range of 100K-500K $LAOS (not USD equivalent). Applicants asking for larger sums will require evidence of considerable impact on our community growth, and even so may be offered a smaller amount than requested."
        },
        {
            title:"Are LAOS grants compatible with those from other chains?",
            text: "Yes. LAOS grants are usually compatible with others. The program is designed to be complementary to grants from other sources."
        },
        {
            title:"Do you offer grants in other denominations, such as USDT?",
            text: "At the moment, the program is only awarding grants in $LAOS token."
        },
        {
            title:"If my project is selected, when will I receive $LAOS?",
            text: "All grants are associated with milestones. Corresponding $LAOS tokens are awarded based on these milestones being completed."
        },
        {
            title:"Will I be able to exchange awarded $LAOS for other currencies?",
            text: "The majority of all grants are associated with a vesting procedure which will unlock tradeable token over time. The $LAOS token will be available for trading on several exchanges."
        },
        {
            title:"Why can't I exchange all my awarded $LAOS straight away?",
            text: "LAOS Network is not a publisher. The grants program is not designed to be the primary funding source for any project; rather it should be thought of a complementary incentive to encourage builders to take advantage of the features of the network."
        },
    ]
}

const Grants = () => {

    return (
    <>
    <section className="">
        <div className="container mx-auto pb-10">
            <div className="grid md:grid-cols-2 place-items-center">
                <div className="pt-10 md:pl-10 text-center md:text-left">
                    <h1 className="text-fvyellow pb-3 header-title text-3xl md:text-5xl leading-none lg:leading-tight">LAOS Network Grants Program</h1>
                    <div className='text-xl md:text-2xl w-3/4 md:w-full mx-auto'>
                    190 Million $LAOS unlocked over two years to fund community growth. 
                    </div>
                    <div className='text-xl md:text-2xl my-8 w-3/4 md:w-full mx-auto'>
                    Level-up your game or DApp with the LAOS Network Grants Program, supporting projects that harness the capabilities of the LAOS platform, as well as contributing to the growth and enrichment of our community.
                    </div>
                    <div className='mb-8'>
                        <a
                        href="https://form.typeform.com/to/A6WvByOu"
                        className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                        >
                        Apply Now
                        </a>
                    </div>
                </div>
                <div className="order-first md:order-last">
                    <img src={hero_image} alt="grants" />
                </div>
            </div>
        </div>
    </section >
    <section className='background-purple-box-radial py-8'>
        <h2 className="section-title text-3xl md:text-4xl my-10 text-center">At a glance</h2>
        <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
        <div className="flex flex-col items-center justify-center">
            <div className="px-4 md:px-0 pb-4 md:pb-4">
                <img src={image_190} alt="190" />
            </div>
            <div className="grid grid-cols-1 md:w-1/2 md:grid-cols-2 items-center justify-center">
                <div className="text-xl md:text-2xl px-4 md:px-0 text-center md:text-left">
                    Grants from 100K to 500K $LAOS per project, delivered according to Milestones. 
                </div>
                <div className="px-10 md:px-0">
                    <img src={image_coins} alt="coins" />
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 md:w-1/2 items-center justify-center">
                <div className="px-10 md:px-0 order-last md:order-first">
                    <img src={image_arrow} alt="coins" />
                </div>
                <div className="text-xl md:text-2xl px-4 md:px-0 text-center md:text-left">
                    Grant size linked to impact on LAOS Network, in terms of chain usage and community growth.   
                </div>
                
            </div>
            <div className="grid grid-cols-1 md:w-1/2 md:grid-cols-2 items-center justify-center">
                <div className="text-xl md:text-2xl px-4 md:px-0 text-center md:text-left">
                    Priority given to projects already launched or lauching soon. 
                </div>
                <div className="px-10 md:px-0">
                    <img src={image_rocket} alt="coins" />
                </div>
            </div>
        </div>
        <div className="container mx-auto pb-10 px-2 md:px-0 md:w-1/2">
            <h2 className="section-title text-3xl md:text-4xl mb-10 mt-16 text-center">How to apply</h2>
            <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
            <div className="text-lg md:text-xl my-6">
                <span className="text-fvorange font-bold">Grant template: </span> 
                 Download and fill-in the <a className="text-fvyellow hover:underline" href="/downloads/LAOS_Grant_Application_Form.docx">grant template</a> to use as a base for your application. 
                You may use this <a className="text-fvyellow hover:underline" href="/downloads/LAOS_Grant_Application_Form_Sample.pdf">sample grant application</a> as a guide. 
            </div>
            <div className="text-lg md:text-xl my-6">
                <span className="text-fvorange font-bold">Submit form: </span>
                Click the button below to start the application process. Fill in the form and
                upload the application form created from the template above.
            </div>
            <div className='mt-16 mb-4 text-center'>
                <a
                href="https://form.typeform.com/to/A6WvByOu"
                className="border border-fvyellow text-fvyellow px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-fvyellow hover:text-fvpurple"
                >
                Apply Now
                </a>
            </div>
        </div>
    </section>
    <section>
        <div className="bg-gradient-to-r from-fvdarkpurple to-fvpurple py-8">
            <h2 className="section-title text-4xl mb-5 text-center">Resources</h2>
            <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored" />
            <div className="container mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3">
                {CONTENT.link_arrows.map((item, i) => {
                    return(
                        <LinkPanelArrow text={item.title} link={item.link} key={i} />
                    )
                })}
            </div>
        </div>
    </section>
    <section className='my-8'>
        <h2 className="section-title text-4xl mb-5 text-center">Grants FAQ</h2>
        <hr className="w-[15%] h-[2px] my-4 mx-auto rounded border-0 hr-colored" />
        <div className="container mx-auto flex flex-col">
            {CONTENT.faq.map((item,i) => {
                return (
                    <DeveloperFAQ key={i} title={item.title} text={item.text} />
                )
            })}
        </div>
    </section>
    <section>
            <Newsletter />
    </section>
    </>
    );
};

export default Grants;