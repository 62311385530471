import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import icon_X from '../img/news/x_icon.png';
import icon_LI from '../img/news/Linkedin_icon.png';
import icon_copy from '../img/news/copylink_icon.png';
// import blog_announce from '../img/news/Laos_blog_announcement.jpg';
import Markdown from 'markdown-to-jsx'
var fm = require('front-matter')

const BlogPost = () => {

  const goToTwitter = () => {
    var fullUrl="https://twitter.com/intent/tweet?url=" + window.location.href;
    window.open(
        fullUrl,
        '_blank' 
      );
  }

  const goToLinkedIn = () => {
    var fullUrl="https://www.linkedin.com/sharing/share-offsite/?url=" + window.location.href;
    window.open(
        fullUrl,
        '_blank' 
      );
  }

  const params = useParams();
  console.log(params);

  const [post, setPost] = useState('');
  useEffect(() => {
    import(`../posts/${params.id}.md`)
          .then(res => {
              fetch(res.default)
                  .then(res => res.text())
                  .then(res => setPost(res))
                  .catch(err => console.log(err));
          })
          .catch(err => console.log(err));
  });

  
  const postFmObject = fm(post);
  const d = new Date(postFmObject.attributes.publishedDate);
  document.title = postFmObject.attributes.title;

  return (
    <>
    <section className="sm:container mx-auto px-8 sm:px-20 lg:px-40 py-10">
        <div className="grid grid-cols-1 md:grid-cols-2 sm:space-x-8">
            <div className="flex flex-col space-y-4">
                <div className="text-4xl"><a href="announcing_laos/" className="hover:underline">{postFmObject.attributes.title}</a></div>
                <div className="text-md">
                    {postFmObject.attributes.description} 
                </div>
                <div className="flex gap-5 pt-8">
                    <div className="text-gray">Share:</div>
                    <img onClick={() => goToTwitter()} className="h-[20px] cursor-pointer" src={icon_X} alt="X.com" />
                    <img onClick={() => goToLinkedIn()} className="h-[20px] cursor-pointer" src={icon_LI} alt="LinkedIn" />
                    <img onClick={() => navigator.clipboard.writeText(window.location.href)} className="h-[20px] cursor-pointer" src={icon_copy} alt="Telegram" />
                </div>
                <div className="text-sm">{d.getDate() + " " + d.toLocaleString('en-US', { month: 'short' }) + " " + d.getFullYear()} by <span className="text-fvyellow">{postFmObject.attributes.author}</span></div>
            </div>
            <div className="flex">
                <div className="justify-between items-center">
                    <img className="rounded-2xl" src={process.env.PUBLIC_URL + postFmObject.attributes.image} alt="news" />
                </div>
            </div>
        </div>
    </section>
    <div className="bg-white text-fvdarkpurple news">
        <hr className="w-[100%] h-[2px] rounded border-0 hr-colored" />
            <section className="container mx-auto px-8 sm:px-20 lg:px-40 py-10 bg-white text-fvdarkpurple">
            <Markdown>{postFmObject.body}</Markdown>
        </section>
    </div>

    </>
  );
};
  
export default BlogPost;