/* eslint-disable react/prop-types */
import React from "react";

import { Helmet } from "react-helmet";
import laos_logo from '../img/LAOS_logo.png';

// import CrowdloanComponent from "./CrowdloanComponent";
export default function Crowdloan() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/tailwind.min.css" />
        <link rel="stylesheet" href="/waitlist.css" />
      </Helmet>
      <section className="sm:mt-[-128px] sm:pt-[128px] pt-10 sm:py-40 bg-cover bg-center sm:bg-[url('./img/crowdloan-bg.png')]">
        <div className="container mx-auto px-10 lg:py-20">
          <div className="sm:w-1/2 grid grid-cols-1 place-items-center">
            <div className="col-span-2 text-left">
              <h1 className="pb-20 sm:pt-20 header-title text-4xl md:text-4xl leading-none lg:leading-tight">
                LAOS Network
                <br/>
                <span className="brandColor">Wins Polkadot Parachain Auction #68</span>
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section
        style={{
          backgroundImage: `linear-gradient(144deg, #25143b 21.25%, #613d93 78.75%)`,
          padding: "0",
          marginTop: "0",
        }}
      >
        {/* <CrowdloanComponent /> */}
      </section>
      <div className="flex justify-center items-center min-h-screen">
        <section
          className="py-12 w-screen "
          style={{
            backgroundImage: `linear-gradient(144deg, #25143b 21.25%, #613d93 78.75%)`,
          }}
        >
          <div className=" px-10 xl:px-32 2xl:px-96">
            <h2 className="text-3xl md:text-3xl brandColor mb-5">
              Crowdloan Rewards Claim Process is Live
            </h2>
            <p className="text-2xl md:text-2xl ">
            Participants in the LAOS Network Crowdloan are now able to claim their rewards at the following
            link:</p>
            <p className="md:text-2xl m-4 text-center"> 
              <a className="text-fvyellow hover:text-fvyellow hover:underline" href="https://crowdloan.laosnetwork.io">https://crowdloan.laosnetwork.io</a>
            </p>
            <p>
            The deadline to claim rewards is August 31st 2024, and rewards will be distributed shortly afterwards. 
            All DOT contributed to the Crowdloan will be automatically unlocked on 28th June 2024. 
            </p>
            <br/>
            <img className="m-auto" src={laos_logo} alt="LAOS" />
            <h3 className="text-2xl md:text-2xl brandColor">Previous News</h3>
            <p><span className="brandColor">27th May 2024</span> -
            The LAOS Foundation is delighted to announce that the full amount initially promised, 100 LAOS tokens per every DOT contributed, will be distributed to the LAOS Crowdloan contributors. 
            </p><br/>
            <p>
            This outcome comes despite the fact that all participants will have every contributed DOT automatically unlocked on June 28th 2024 (instead of June 2026), due to the technical issues previously reported.
            </p><br/>
            <p>
            At the end of June 2024, the LAOS Foundation will make available a website where contributors will be able to associate the account where their rewards are to be transferred (*). Following legal advice, and to ensure that all rewards are granted in accordance with the <a href="/downloads/Terms_and_Conditions_LAOS_Chain_Crowdlon.pdf" target="_blank" rel="noreferrer"> Terms and Conditions of the Crowdloan</a>, all contributors will be required to follow some simple KYC (Know-Your-Customer) steps in order to receive their rewards.
            </p><br/>
            <p>
            All rewards must be claimed by 31st August, 2024. Any unclaimed LAOS token rewards on this date will be burned. Claimed rewards will be distributed shortly after this date.
            </p><br/>
            <p>
            The list of Polkadot accounts eligible for rewards can be found <a href="https://github.com/freeverseio/laos/blob/main/scripts/crowdloan_rewards/crowdloan.md" target="_blank" rel="noreferrer">in this document</a>. If you contributed via Bifrost, your entry in that list will correspond to your address formatted for Polkadot. You can find the mapping between all Bifrost contributors and their Polkadot addresses <a href="https://github.com/freeverseio/laos/blob/main/scripts/crowdloan_rewards/bifrost_to_polkadot.md" target="_blank" rel="noreferrer">here</a>, or <a href="https://polkadot.subscan.io/tools/format_transformer" target="_blank" rel="noreferrer">use any standard tool to convert</a>. Note that, as communicated via all channels, only contributions made during the validity period of Auction #68 are entitled to receive rewards. Auction #68 finished at <a href="https://polkadot.subscan.io/block/20549306" target="_blank" rel="noreferrer">Polkadot&apos;s block 20549306</a>, and all contributions made after this block will not be rewarded.
            </p><br/>
            <p>
            (*) Since LAOS Network uses Ethereum-format (H160) addresses, every contributor will need to associate a valid H160 address to which the LAOS tokens will be transferred. This association will be done by signing with the same Polkadot (SS58) address used to contribute to the Crowdloan. The website will help contributors complete this step.
            </p>            
            <br/>
            <p>
            <span className="brandColor">29th April 2024</span> - LAOS Network Wins Polkadot Parachain Auction #68. 
            LAOS Network has won Polkadot parachain auction #68, for a lease beginning on July 3rd 2024.
            <br/>
            </p><br/>
            <p>
            <span className="brandColor">26th April 2024</span> - As promised, we have submitted a proposal to the Polkadot governance to adjust the 
            Crowdloan lease period. The proposal can be found here:
            <br/>
            <a href="https://polkadot.polkassembly.io/referenda/716">https://polkadot.polkassembly.io/referenda/716</a>  
            </p><br/>
            <p>
            <span className="brandColor">25th April 2024</span> - As we reported yesterday, the contributions to the LAOS Crowdloan are unable 
              to be used for any auctions in the current batch, due to a technical misconfiguration. 
              Also as reported, we are finalizing a submission to the Polkadot governance to allow 
              these contributions to count for the last auctions of the batch.
            </p><br/>
            <p>
              While this submission continues its due course, in parallel, we have decided to try to 
              win the current auction with the LAOS Foundation&apos;s own resources, with a nominal bid. 
            </p><br/>
            <p>
              These are the possible future scenarios:
            </p><br/>
            <ol className="list-decimal list-inside">
              <li className="mb-4"><span>If the current bid is enough to win the auction:</span>
                <ul className="list-disc list-inside">
                  <li>We will secure the slot, allowing LAOS TGE to take place after July 3rd. </li>
                  <li className="font-bold">Your DOT will be unlocked on June 28th, 2024.</li>
                  <li>The list of all of you who contributed to the Crowdloan is on-chain, and we will
                     explore all possibilities to ensure that these contributions are recognized.
                  </li>
                </ul>
              </li>
              <li className="mb-4"><span>If the current bid is not enough to win the auction or if the candle auction selects an end time 
                earlier than the block of the nominal bid, we will wait for the governance resolution:</span>
                <ul className="list-disc list-inside">
                  <li>If positive, all contributions will allow us to compete for the last auctions of the batch.</li>
                  <li>If negative, we will consider either going for the next batch, or waiting for agile core-time.&nbsp; 
                    <span className="font-bold">Your DOT will be unlocked on June 28th, 2024.</span>.
                  </li>
                </ul>
              </li>
            </ol>

            <p>
            We will know about the impact of the current bid at the end of Auction #68. 
            We will post details about the governance request as soon as it is made.
            </p><br />

            <p>
            <span className="brandColor">24th April 2024</span> - The LAOS team has detected an issue with the association of the Crowdloan contributions 
            to their corresponding effect as bids for Auctions #68-73. As a result, the LAOS Crowdloan 
            contributions do not currently appear as usable for the Auctions in the current batch.
            </p><br/>
            <p>
            We are submitting a request to the Polkadot governance in order to try to correct the issue. 
            Should this be successful, the Crowdloan might be able to bid for later auctions in the current 
            batch.</p><br/>
            <p>
            Should this request be unsuccessful, the Crowdloan will run its course naturally and expire on 
            the 28th of June 2024. As is normal, at this time all the DOT that were contributed to the 
            Crowdloan will be unlocked for their owners (i.e. the normal behaviour for an unsuccessful Crowdloan).
            </p><br/>
            <p>
              <span>Given the situation, we are now recommending that the LAOS community 
              PAUSES its contributions to the existing crowdloan</span> until we have a clearer understanding 
              of the next steps.
            </p><br/>

            <p>
            Obviously, all of the LAOS team is crushed by this news, as it looked as if we were on-course
             to win Auction 68. We are currently working hard to try and resolve the issue and get the 
             crowdloan back on track. As soon as we have a better understanding of the options ahead of us, 
             we will maintain an open and transparent communication strategy, and inform the community as 
             soon as possible.

            </p><br/>
            <h3 className="text-2xl md:text-2xl brandColor">
              Terms and Conditions
            </h3>
            <p>
              <a href="/downloads/Terms_and_Conditions_LAOS_Chain_Crowdlon.pdf" target="_blank" rel="noreferrer">Crowdloan Terms and Conditions</a>
            </p>

          </div>
        </section>
      </div>
    </>
  );
}