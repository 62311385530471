import React from 'react';
import com_discord from "../../img/discord.svg";
import com_x from "../../img/x_icon.svg";
import com_tele from "../../img/telegram.png";
import com_LI from "../../img/Linkedin_Icon.svg";
import com_git from "../../img/github_logo.svg";
import com_yt from "../../img/Youtube_Icon.svg";

const CommunityPanels = () => {
    return (
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
            <div
              onClick={() => {
                window.location.href = "https://discord.gg/gZcxsJcdPy";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img
                  className="h-[70px] pb-5"
                  src={com_discord}
                  alt="Discord"
                />
              </div>
              <div className="text-2xl font-bold">Discord</div>
              <div className="text-m">Community</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://twitter.com/laosnetwork";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_x} alt="X" />
              </div>
              <div className="text-2xl font-bold">X.com</div>
              <div className="text-m">Announcements</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://t.me/+qI2I19EI-VAzNGVk";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_tele} alt="Telegram" />
              </div>
              <div className="text-2xl font-bold">Telegram</div>
              <div className="text-m">Community</div>
            </div>
            <div
              onClick={() => {
                window.location.href =
                  "https://www.linkedin.com/company/laosnetwork/";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_LI} alt="LinkedIn" />
              </div>
              <div className="text-2xl font-bold">LinkedIn</div>
              <div className="text-m">Announcements</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://github.com/freeverseio/laos";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_git} alt="Github" />
              </div>
              <div className="text-2xl font-bold">Github</div>
              <div className="text-m">Developers</div>
            </div>
            <div
              onClick={() => {
                window.location.href = "https://www.youtube.com/@laosnetwork";
              }}
              className="hover:cursor-pointer flex flex-col p-7 rounded-lg border-2 border-fvgreypurple hover:border-fvyellow hover:bg-gradient-to-br from-fvdarkpurple to-fvpurple"
            >
              <div>
                <img className="h-[70px] pb-5" src={com_yt} alt="Youtube" />
              </div>
              <div className="text-2xl font-bold">Youtube</div>
              <div className="text-m">Tutorials & Guides</div>
            </div>
          </div>
    );
};

export default CommunityPanels;