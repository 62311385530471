/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";

export default function RoadmapAfterTGE() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/tailwind.min.css" />
        <link rel="stylesheet" href="/waitlist.css" />
        <style>
          {`
            .table-auto th, .table-auto td {
              background-color: rgba(255, 255, 255, 0);
              border: 1px solid #ddd;
            }
            .table-auto th {
              background-color: rgba(97, 61, 147, 0.8); /* Assuming this is your brand color */
              color: #fff;
            }
            .table-auto td:first-child {
              background-color: rgba(97, 61, 147, 0.8); /* Assuming this is your brand color */
              color: #fff;
            }
            .nested-list {
              margin-left: 2rem; /* Increased left margin for more padding */
            }
            .responsive-table {
              overflow-x: auto;
            }
          `}
        </style>
      </Helmet>
      <section className="lg:mt-[-128px] lg:pt-[128px] pt-10 sm:py-20 bg-cover bg-center sm:bg-[url('./img/roadmap_bg.jpg')]">
        <div className="container mx-auto px-10 lg:py-20">
          <div className="sm:w-2/3 grid grid-cols-1">
            <div className="col-span-2 text-left">
              <h1 className="pb-20 sm:pt-20 header-title text-4xl md:text-4xl leading-none lg:leading-tight">
                Short-term Roadmap After TGE
              </h1>
            </div>
          </div>
        </div>
      </section>

      <div className="flex justify-center items-center">
        <section
          className="py-12 w-screen "
          style={{
            backgroundImage: `linear-gradient(144deg, #25143b 21.25%, #613d93 78.75%)`,
          }}
        >
          <div className="px-10 xl:px-32 2xl:px-96">
          <p>
              The Parachain lease for the LAOS Network begins on July 3rd, 2024. Any time after this date, LAOS will be able to start producing blocks, which will be validated by Polkadot&apos;s Relay Chain validators.
            </p>
            <br />
            <p>
              The roadmap for the first few months after LAOS&apos;s first produced block (the TGE event) has been public on <a href="https://laosnetwork.io/" className="text-fvyellow hover:text-fvyellow hover:underline">laosnetwork.io</a> for quite some time, with new details being added as they become clear.
            </p>
            <br />
            <p>
              In this post, we want to expand on that short-term roadmap, and delve into all lower-level details we have good visibility on. We believe that transparency and information sharing will be key as we transition to fully decentralized governance towards Q4 this year.
            </p>
            <p>
              Here&apos;s the timeline. It is very fine-grained during the first weeks and necessarily gets more coarse-grained as we approach Q4. Remember that registrations for the <a href="/token-sale" className="text-fvyellow hover:text-fvyellow hover:underline">token public pre-sale</a> are already open.
            </p>
            <br />
            <ul className="list-disc list-inside">
            <p className="brandColor">
                <strong>July 3</strong>
              </p>
                <ul className="nested-list list-disc">
                  <li><b>The Parachain lease begins</b>. The LAOS Foundation will set up the first two collators and start producing blocks. No external transaction will go through the platform, no transfers. Only block production. We will monitor network robustness and stability. Anyone will be able to check block production on <a href="https://polkadot.js.org/apps/?rpc=wss%3A%2F%2Frpc.laos.laosfoundation.io#/explorer" className="text-fvyellow hover:text-fvyellow hover:underline">LAOS@Polkadot.js</a>.</li> 
                  <li>Permissionless Token Transfers will <b>not</b> be enabled.</li>
                </ul>
              <br />
              <p className="brandColor">
                <strong>July 11</strong>
              </p>
                <ul className="nested-list list-disc">
                  <li><b>Runtime Upgrade Check</b>. The dev team will conduct the first runtime upgrade, to triple-check viability for future upgrades.</li>
                  <li><b>Initial Pools and Distribution</b>. Following the <a href="https://laos.gitbook.io/laos-whitepaper/tokenomics/token-distribution" className="text-fvyellow hover:text-fvyellow hover:underline">published tokenomics</a>, the main pools will be created, and the distribution to early backers, new investors, and core team will be executed, all with the corresponding published cliff and vesting conditions.</li>
                  <li>Permissionless Token Transfers will still <b>not</b> be enabled, but token owners will be able to start delegating.</li>
                </ul>
              <br />
              <p className="brandColor">
                <strong>July 11 to July 15</strong>
              </p>
                <ul className="nested-list list-disc">
                  <li><b>Public Sale via Tokensoft and Bit2Me</b>. Check the currencies available to execute the purchase, and the registration details <a href="/token-sale" className="text-fvyellow hover:text-fvyellow hover:underline">here</a>.</li>
                  <li>Permissionless Token Transfers will still <b>not</b> be enabled.</li>
                </ul>
              <br />
              <p className="brandColor">
                <strong>July 16 to July 23</strong>
              </p>
                <ul className="nested-list list-disc">
                  <li><b>Public Pre-Sale Distribution</b>. Tokens will be distributed to those who purchased during the public pre-sale.</li>
                  <ul className="nested-list list-disc">
                    <li>For tokens purchased via Tokensoft, the LAOS Foundation will receive from Tokensoft the list of Web3 addresses and amounts of tokens purchased, and will execute the transfers directly, again, enforcing the 12-months vesting conditions.</li>
                    <li>For tokens purchased via Bit2Me, the LAOS Foundation will execute a transfer to Bit2Me&apos;s account, with the published 12-months vesting conditions. As an exchange, Bit2Me will be the custodian of those tokens, and distribute them monthly to their users as they vest.</li>
                  </ul>
                  <li>Permissionless Token Transfers will still <b>not</b> be enabled.</li>
                  <li>Note that the completion of the distribution depends on both launchpads providing the corresponding addresses, hence the uncertainty in fixing a particular date.</li>
                  <li>Note also that the vesting of these tokens is counted from the production of the first block, so there will be a corresponding fraction of tokens already vested when distributed.</li>
                </ul>
              <p>
                <strong className="brandColor">July 25th update:</strong> Tokens have been distributed. Please visit <a className="brandColor hover:underline hover:text-fvyellow" href="https://vesting.laosnetwork.io">https://vesting.laosnetwork.io</a> to connect your wallet and see the status of your tokens.
              </p>
              <br />
              <p className="brandColor">
                <strong>August to end of Q3</strong>
              </p>
                <ul className="nested-list list-disc">
                  <li><b>Enabling Permissionless Transfers and EVM activation</b>. Both milestones will be deployed when the corresponding audit for the runtime is completed. After an initial fuzzy-test phase by SR Labs in April, the code for the various runtimes that will lead to activating the EVM and making token transfers permissionless is being audited by <a href="https://www.coinfabrik.com/services/smart-contract-audits/" className="text-fvyellow hover:text-fvyellow hover:underline">Coinfabrik</a>, one of the <a href="https://polkadotassurance.com/" className="text-fvyellow hover:text-fvyellow hover:underline">auditors approved by Polkadot&apos;s Assurance Legion</a>.</li>
                  <ul className="nested-list list-disc">
                    <li>After this milestone, the main core features of LAOS - bridgeless minting, and decentralized asset metadata extensions - will be live on mainnet.</li>
                  </ul>
                  <li><b>Zealy Rewards Distribution</b>. Rewards from Zealy Sprint 1 and Sprint 2 will be distributed.</li>
                  <li><b>Transition to permissionless collators</b>. This milestone will be the first of many steps towards full decentralization, allowing anyone to become a collator, subject to the standard rules - depositing a stake, receiving enough delegation, etc.</li>
                </ul>
              <br />
              <p className="brandColor">
                <strong>September&apos;s 1st Week</strong>
              </p>
                <ul className="nested-list list-disc">
                  <li><b>Crowdloan Rewards Distribution</b>. The <a href="https://crowdloan.laosnetwork.io/" className="text-fvyellow hover:text-fvyellow hover:underline">website</a> made available to all LAOS crowdloan contributors to claim their rewards will be live until August 31st. Shortly after this deadline, a transfer will be made to all successfully-verified contributors with their corresponding rewarded LAOS tokens. As in the case of the public pre-sale, the vesting of these tokens <a href="https://laos.gitbook.io/laos-whitepaper/tokenomics/token-distribution" className="text-fvyellow hover:text-fvyellow hover:underline">(in this case 24 months)</a> will be counted from the production of the first block, so there will be a corresponding fraction of tokens already vested when distributed.</li>
                </ul>
              <br />
              <p className="brandColor">
                <strong>Q4 2024</strong>
              </p>
                <ul className="nested-list list-disc">
                  <li><b>Migration towards decentralized governance</b>. We will integrate with Polkadot&apos;s decentralized governance modules, ultimately allowing token holders to decide on LAOS&apos;s future.</li>
                  <li><b>Facilitating the exchange of LAOS tokens</b>. To this end, we will integrate XCM transfers with other parachains so that the token can be exchanged in existing DEXs that operate in the Polkadot ecosystem. Additionally, the LAOS Foundation will pursue the completion of ongoing negotiations with several CEXs to list the token.</li>
                </ul>
              <br />
              <p>At a core level, an important feature that will be pursued will be the integration of precompiles that allow access to staking and vesting from the EVM side of the chain. This will enable anyone to delegate or withdraw vested tokens using wallets from the Ethereum ecosystem, such as Metamask, as <a href="https://laosnetwork.io/wallet-compatibility-roadmap" className="text-fvyellow hover:text-fvyellow hover:underline">described in full detail here</a>.</p>
              <br />
            </ul>
          </div>
        </section>
      </div>
    </>
  );
}
