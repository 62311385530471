import React from 'react';
import PropTypes from 'prop-types';

const EmbedVideo = props => {
    return (
        <div>
            <h2 className="section-title text-4xl mb-5 text-center">
            {props.title}
            </h2>
            <hr className="w-[15%] h-[2px] my-4 rounded border-0 hr-colored mx-auto" />
            <div className="aspect-video">
                <iframe
                title="youtube"
                className="w-full h-full"
                src={props.url}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

EmbedVideo.propTypes = {
    title: PropTypes.string,
    url: PropTypes.string
};

export default EmbedVideo;