/* eslint-disable react/prop-types */

import React from 'react';

const ArrowDown = (props) => {
    const theColor = props.color || "currentColor"
    return (
        <div className="text-white hover:underline inline-block w-[14px] h-auto ml-[10px]">
            <svg data-name="Layer 1" viewBox="0 0 24 14.15">
                <path
                    d="m12 14.15-12-12L2.15 0 12 9.9 21.85.05 24 2.2 12 14.15Z"
                    fill={theColor}
                ></path>
            </svg>
        </div>
    )
}

export default ArrowDown;