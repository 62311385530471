import React, { useState } from "react";
import laos_logo from "../img/LAOS_logo.png";
import HomeBanner from "./HomeBanner";
import MenuDesktop from "./menu/MenuDesktop";
import MenuMobile from "./menu/MenuMobile";

const Header = () => {
  const [showMobileMenu, setShowMobileMenu] = useState(false);

  return (
    <header
      className="px-10 py-6 mt-10"
      style={{ zIndex: 1000, position: "relative" }}
    >
      <HomeBanner />
      <div className="flex justify-between items-center">
        <div className="flex items-center space-x-4">
          <a href="/">
            <img src={laos_logo} alt="LAOS Logo" className="h-20" />
          </a>
          <div>
            <a href="/" className="header-title tracking-widest text-fvyellow text-5xl">
              LAOS
            </a>
            <div className="header-title tracking-widest text-white text-xs">
              The home of digital assets
            </div>
          </div>
        </div>
        <div className="hamburger flex lg:hidden">
          <button
            onClick={() => setShowMobileMenu(!showMobileMenu)}
            className="text-white text-3xl focus:outline-none">
            <i className="fas fa-bars"></i>
          </button>
        </div>
        {/* <!-- Desktop Navigation --> */}
        <MenuDesktop />
      </div>
      {/* <!-- Mobile Navigation --> */}
      <div className={showMobileMenu? "mobile-nav lg:hidden": "mobile-nav hidden lg:hidden"}>
        <MenuMobile />
      </div>
    </header>
  );
};

export default Header;
