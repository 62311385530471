/* eslint-disable react/prop-types */
import React from 'react';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import planet from "../../img/planets_community.png";

// a basic form
const CustomForm = ({ status, message, onValidated }) => {
    let email;
    const submit = () =>
      email &&
      email.value.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email.value,
      });
  
    return (
      <div className="bg-red border-sm pt-2 inline-block">
        {status === "sending" && <div className="text-fvyellow">Sending...</div>}
        {status === "error" && (
          <div
            className="text-red-500"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className="text-green-500"
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        <div className="flex flex-col sm:flex-row py-2 space-y-4 sm:space-y-0">
          <input
            className="w-[300px] text-slate-800 w-full rounded-lg mr-3 py-3 px-2 leading-tight"
            ref={(node) => (email = node)}
            type="email"
            placeholder="Your email"
          />
          <button
            className="w-40 flex-shrink-0 border-2 bg-fvyellow border-fvyellow text-fvpurple px-8 py-2 rounded-full transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
            onClick={submit}
          >
            {" "}
            Submit{" "}
          </button>
        </div>
      </div>
    );
  };

const Newsletter = props => {

    const mcUrl =
    "https://laosnetwork.us21.list-manage.com/subscribe/post?u=a8a30830e9abfb70305c78868&amp;id=e497a4be7c&amp;f_id=0022eee6f0";


    return (
        <div className="bg-fvpurple w-auto">
            <div className="container mx-auto px-10">
            <div className="flex flex-row items-center">
                <div className="py-10">
                <h3 className="text-4xl font-bold">Subscribe</h3>
                <p>
                    Stay informed about the project updates, and the upcoming token
                    launch
                </p>
                <MailchimpSubscribe
                    url={mcUrl}
                    render={({ subscribe, status, message }) => (
                    <CustomForm
                        status={status}
                        message={message}
                        onValidated={(formData) => subscribe(formData)}
                    />
                    )}
                />
                <p className="text-xs">
                    By clicking &apos;Submit&apos; you accept LAOS&apos;
                    <a
                    className="text-fvyellow hover:underline"
                    href={process.env.PUBLIC_URL + "/downloads/Disclaimer.pdf"}
                    >
                    {" "}
                    Terms of Use
                    </a>{" "}
                    and
                    <a
                    className="text-fvyellow hover:underline"
                    href={process.env.PUBLIC_URL + "/downloads/Privacy.pdf"}
                    >
                    {" "}
                    Privacy Policy
                    </a>
                </p>
                </div>
                <div>
                <img
                    className="hidden sm:block ml-20 h-40 mix-blend-plus-lighter"
                    src={planet}
                    alt="planet"
                />
                </div>
            </div>
        </div>
      </div>
    );
};


export default Newsletter;