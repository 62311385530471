/* eslint-disable react/prop-types */
import React from 'react';

const MenuItem = (props) => {
    return (
        <div className="flex flex-row w-full space-x-4">
            <div className="w-1/4">
                <a href={props.link} >
                    <img src={props.icon} target={props.target} alt="icon" />
                </a>
            </div>
            <div className="flex flex-col w-3/4">
                <a href={props.link} target={props.target} className="font-bold">{props.title}</a>
                <a href={props.link} target={props.target} className="text-xs">{props.desc}</a>
            </div>
        </div>
    )
}

const MenuDropdownMobile = (props) => {
    const contents = props.contents
    return (
        <div className='block mt-0 ml-0 p-4 w-full top-[calc(100%+20px)] right-0 rounded-b-xl
        bg-[linear-gradient(50deg,var(--tw-gradient-stops))] from-fvyellow via-20% via-fvorange to-70% to-fvpurple'>
            <div className="flex flex-col space-y-4">
                <div className="flex flex-row w-full space-x-4">
                    <div className="w-1/4">
                        <a href={contents.link}>
                            <img src={contents.image} alt="icon" />
                        </a>
                    </div>
                    <div className="flex flex-col w-3/4">
                        <div className="font-bold">{contents.title}</div>
                        <div className="text-xs">{contents.text}</div>
                        <a href={contents.link} className="text-sm text-fvyellow">Learn more</a>  
                    </div>
                </div>
                <div className="h-[1px] bg-fvpurple w-full"></div>
                {contents.menu_items.map((item, i) => {
                    return (
                        <MenuItem 
                            key={i}
                            title={item.title}
                            desc={item.desc}
                            icon={item.icon}
                            link={item.link} 
                            target={item.target}/>
                    )
                })}
            </div>
        </div>
    );
};

export default MenuDropdownMobile;