import React, { useState, useRef, useEffect  } from 'react';
import MenuDropdownDesktop from './MenuDropdownDesktop';
import ArrowDown from '../components/ArrowDown';
import { NetworkMenu, BuildMenu, CommunityMenu, AboutMenu } from './MenuContents';


const MenuDesktop = () => {
    

    const [showBuildMenu, setShowBuildMenu] = useState(false);
    const [showNetworkMenu, setShowNetworkMenu] = useState(false);
    const [showCommunityMenu, setShowCommunityMenu] = useState(false);
    const [showAboutMenu, setShowAboutMenu] = useState(false);

    //we assign this ref to the menu bar and created dropdowm
    const buildRef = useRef(null)
    const networkRef = useRef(null)
    const communityRef = useRef(null)
    const aboutRef = useRef(null)
    //this effect listens to the the window mousedown
    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    //this events closes all open menus
    const handleClickOutside = (event) => {
        if (buildRef.current && !buildRef.current.contains(event.target)) 
            setShowBuildMenu(false)
        if (networkRef.current && !networkRef.current.contains(event.target))
            setShowNetworkMenu(false) 
        if (communityRef.current && !communityRef.current.contains(event.target))
            setShowCommunityMenu(false)   
        if (aboutRef.current && !aboutRef.current.contains(event.target))
            setShowAboutMenu(false)         
        
    }

    return (
        <nav className="nav-links lg:flex hidden items-center space-x-8 text-sm">
          <div className="relative flex flex-row">
            <div>
                <div className="text-white hover:underline transition duration-300 ease-in-out mr-8"
                    onClick={() => setShowBuildMenu(!showBuildMenu)}>
                    Build
                    <ArrowDown />
                </div>
                <div ref={buildRef}>
                    {showBuildMenu && <MenuDropdownDesktop contents={BuildMenu} />}
                </div>
            </div>
            <div>
                <div className="text-white hover:underline transition duration-300 ease-in-out mr-8"
                    onClick={() => setShowNetworkMenu(!showNetworkMenu)}>
                    Network
                    <ArrowDown />
                </div>
                <div ref={networkRef}>
                    {showNetworkMenu && <MenuDropdownDesktop contents={NetworkMenu} />}
                </div>
            </div>
            <div>
                <div className="text-white hover:underline transition duration-300 ease-in-out mr-8"
                    onClick={() => setShowCommunityMenu(!showCommunityMenu)}>
                    Community
                    <ArrowDown />
                </div>
                <div ref={communityRef}>
                    {showCommunityMenu && <MenuDropdownDesktop contents={CommunityMenu} />}
                </div>
            </div>
            <div>
                <div className="text-white hover:underline transition duration-300 ease-in-out mr-8"
                    onClick={() => setShowAboutMenu(!showAboutMenu)}>
                    About
                    <ArrowDown />
                </div>
                <div ref={aboutRef}>
                    {showAboutMenu && <MenuDropdownDesktop contents={AboutMenu} />}
                </div>
            </div>
            <div>
                <a
                href="/community"
                    className="border border-fvyellow bg-fvyellow text-fvpurple px-4 py-2 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                >
                    Join Community
                </a>
            </div>
          </div>
        </nav>
    );
};

export default MenuDesktop;