import React from 'react';
import { Widget } from '@typeform/embed-react'

const Collaborate = () => {
  return (
    <>
    <section className="sm:container mx-auto px-8 sm:px-20 lg:px-40 py-10 h-[70vh]">
        <Widget id="ETlGdlku" style={{ height: '60vh', border: 'solid 1px yellow', borderRadius: '10px' }} className="my-form" />
    </section>
    
    </>
  );
};
  
export default Collaborate;