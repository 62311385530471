import React from "react";
import css from "./HomeBanner.module.css";
import lightning from "../img/lightning-charge.svg";

const HomeBanner = () => {
  return (
    // eslint-disable-next-line react/jsx-no-target-blank
    <div
      className={css.banner} 
    >
      <img
        src={lightning}
        width="16"
        height="16"
        alt="LAOS Network Grant Program
        "
      />{" "}
      <a data-testid="banner-text"
        href="/grant-program"
        target="_self">
        LAOS Network Grant Program now live and accepting applications!
      </a>
    </div>
  );
};

export default HomeBanner;
