import React from 'react';
import CommunityPanels from './components/CommunityPanels';
import GrantPanel from './components/GrantPanel';
import Newsletter from './components/Newsletter';


const Community = () => {
    return (
        <>
        <section>
            <div className="bg-[radial-gradient(ellipse_at_left_top,_var(--tw-gradient-stops))] from-fvorange from-0% via-fvpurple to-fvmidpurple to-50% py-20">
                <div className="w-3/4 sm:w-1/2 mx-auto py-10 flex flex-col">
                    <h1 className="text-center text-fvyellow font-bold text-2xl sm:text-4xl mb-8">Join LAOS’s growing community</h1>
                    <h5 className="text-center sm:text-lg mb-8">LAOS Network is designed to bring digital assets to billions of users. Join our community of users, builders and token holders and help bring LAOS to the next level.</h5>
                </div>

                <div className="container mx-auto">
                <CommunityPanels />
                </div>
            </div>
        </section>
        <section>
            <div className="bg-[url('./img/community_img.png')] bg-no-repeat bg-cover bg-left sm:bg-center">
                <div className="container mx-auto">
                    <div className='md:w-1/2 py-20 px-4'>
                        <div className="header-title text-4xl">
                            Become a LAOS Ambassador
                        </div>
                        <div className="mt-4 mb-8">
                        LAOS Network ambassadors play a vital role in the growth of our community and ecosystem. We provide all our ambassadors with the knowledge and support to push LAOS in each community that they form a part of. If you are passionate about bringing digital ownership to the masses, come and help us spread the word!
                        </div>
                        <a
                        href="mailto:info@laosnetwork.io"
                        className="border border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out bg-fvyellow hover:bg-transparent hover:text-fvyellow"
                        >
                        Get Involved
                        </a>
                    </div>
                </div>
            </div>
        </section>
        <section>
            <GrantPanel />
        </section>
        <section>
            <Newsletter />
        </section>
        </>
    );
};

export default Community;