import i_main_image from "./img/blog_post_1.jpg"
import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"
import i_slides_image4 from "./img/4.jpg"
import i_slides_image5 from "./img/5.jpg"


var CaseStudyData = {
  release: "Coming Q4 2024",
  title: "MetaverseME",
  text: `The MetaverseME platform empowering users and creators 
          to explore their digital selves through connected experiences. 
          LAOS assets are being integrated not only into the new beta release of 
          the ME! Avatar App for Android and iOS but also in the upcoming multiplayer 
          football game KickOff Evolution.`,
  main_image: i_main_image,
  button_text: "More info",
  button_link: "https://www.metaverseme.com/",
  developer: "MetaverseME",
  genre: "Platform",
  OS: "Mobile",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
    i_slides_image4,
    i_slides_image5,
  ],
  body_title: "A gateway to a dynamic digital universe",
  body_text_paras: [
    `The MetaverseME platform empowering users and creators 
          to explore their digital selves through connected experiences. 
          The ME! Avatar App is your gateway to a dynamic digital universe, 
          where you can create a unique avatar, dive into thrilling games, 
          capture AR videos, and showcase your style on social media.`,
    `The monetization model for Free-to-Play (F2P) games relies on achieving high install volumes, with the understanding that only a small percentage of users will make in-game purchases. However, this approach encounters significant challenges in the Web3 space, where in-game assets are tokenized on the blockchain. Despite notable advancements in blockchain technology, such as faster speeds and improved throughput, scaling tokenized assets to the level required for F2P success remains difficult. High transaction fees and network limitations create bottlenecks, making it challenging for Web3 games to support the large user bases that F2P titles typically depend on.`,
    `This challenge is being addressed through a strategic partnership between MetaverseME and the LAOS Network. LAOS’s cutting-edge technology, which enables scalable asset minting across multiple blockchains, allows F2P games to seamlessly generate large quantities of in-game assets for every player, regardless of whether they make purchases. This breakthrough capability removes a key barrier to adoption, enabling Free-to-Play mechanics to thrive in a blockchain environment. MetaverseME plans to leverage this innovation in their upcoming F2P releases, paving the way for a new era of blockchain gaming.`,
    `MetaverseME will multiple asset collections using LAOS in 2024 and 2025. These assets will be integrated not only into the new beta release of the ME! Avatar App for Android and iOS but also in the upcoming multiplayer football game KickOff Evolution, and other games, becoming an integral part of the MetaverseME ecosystem. `,
  ],
}
export default CaseStudyData;