import React from 'react';
// import background_image from '../../img/grant_bg_small.png'
import splash_image from '../../img/grant_img_transparent.png'

const GrantPanel = () => {
    return (
        <>
        <div className="bg-fvdarkpurple py-40 relative">
            <div className="absolute hidden md:block top-30">
                <img src={splash_image} alt="grants" className="w-2/3 lg:w-1/2" />
            </div>
            <div className="bg-[url('./img/grant_bg_small.png')] bg-no-repeat bg-cover bg-center">
                <div className="container mx-auto px-20 py-20">
                    <div className="md:w-1/3 ml-auto">
                        <div className="header-title text-2xl">Build on LAOS</div>
                        <p>The LAOS Foundation Grants Program is designed 
                        to empower a broad spectrum of blockchain initiatives, focusing on both 
                        technological innovation and vibrant community involvement.</p>
                        <div className="mt-8">
                            <a
                            href="/grant-program"
                            className="border bg-fvyellow border-fvyellow text-fvpurple px-8 py-3 rounded transition duration-300 ease-in-out hover:bg-transparent hover:text-fvyellow"
                            >
                            Apply now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div> 
        </>
    );
};

export default GrantPanel;