/* eslint-disable react/prop-types */
import React, { useState } from "react";

export function Tabs({ children }) {
  function findActiveTab(a) {
    return a.reduce((accumulator, currentValue, i) => {
      if (currentValue.props.active) {
        return i;
      }

      return accumulator;
    }, 0);
  }

  const [activeTab, setActiveTab] = useState(findActiveTab(children));
  
  return (
    <>
      <div className="flex justify-center">
        {children.map((item, i) => {
          return (
            <Tab
                  key={i}
                  currentTab={i}
                  activeTab={activeTab}
                  setActiveTab={setActiveTab}
                >
                  {item.props.children}
            </Tab>
          );
        })}
      </div>
      <div>
        {children.map((item, i) => {
          return (
            <div key={i} className={`p-10 rounded-b-xl bg-[linear-gradient(130deg,var(--tw-gradient-stops))] from-30% from-fvpurple via-fvorange to-fvyellow
             ${i === activeTab ? "visible" : "hidden"}`}>
              {item.props.component}
            </div>
          );
        })}
      </div>
    </>
  );
}

export function Tab({ children, activeTab, currentTab, setActiveTab }) {
  return (
    <>
    <div className="flex flex-col grow">
        <div
            className={`grow text-xl px-5 py-3 cursor-pointer text-center
        ${activeTab === currentTab ? "text-white" : "text-slate-300"}`}
            onClick={() => setActiveTab(currentTab)}
        >
            {children}
        </div>
        <div className={`h-[2px]
        ${activeTab === currentTab ? "bg-gradient-to-r from-fvorange to-fvpurple" : "bg-slate-300"}
        `}
        
        ></div>
    </div>
    </>
  );
}

Tab.displayName = "Tab";