import i_main_image from "./img/portada2.jpg"
import i_slides_image1 from "./img/1.jpg"
import i_slides_image2 from "./img/2.jpg"
import i_slides_image3 from "./img/3.jpg"
import i_slides_image4 from "./img/4.jpg"
import i_slides_image5 from "./img/5.jpg"
import i_slides_image6 from "./img/6.jpg"
import i_slides_image7 from "./img/7.jpg"
import i_slides_image8 from "./img/8.jpg"
import i_slides_image9 from "./img/9.jpg"
import i_slides_image10 from "./img/10.jpg"
import i_slides_image11 from "./img/11.jpg"
import i_slides_image12 from "./img/12.jpg"
import i_slides_image13 from "./img/13.jpg"
import i_slides_image14 from "./img/14.jpg"

var CaseStudyData = {
  release: "Coming Q4 2024",
  title: "Netherak Demons",
  text: `Netherak Demons is a Diablo-inspired action RPG (ARPG) set in a dark, 
  immersive world where players battle in an epic conflict between humans and demons. 
  The game blends traditional ARPG mechanics with innovative web3 features, such as tradable NFTs 
  that unlock exclusive content, quests, and rewards. In addition to its main campaign, 
  The Sons of Evil, which focuses on the war between humans and demons, players 
  can explore standalone acts that run parallel to the core storyline, 
  expanding the game’s rich lore and content.`,
  main_image: i_main_image,
  button_text: "More info",
  button_link: "https://www.netherak.com/",
  developer: "Netherak Entertainment",
  genre: "ARPG",
  OS: "PC",
  slider_images: [
    i_slides_image1,
    i_slides_image2,
    i_slides_image3,
    i_slides_image4,
    i_slides_image5,
    i_slides_image6,
    i_slides_image7,
    i_slides_image8,
    i_slides_image9,
    i_slides_image10,
    i_slides_image11,
    i_slides_image12,
    i_slides_image13,
    i_slides_image14,
  ],
  body_title: "Netherak Demons: Dark ARPG with Web3 Integration",
  body_text_paras: [
    `Netherak Demons, the action-packed, Diablo-inspired ARPG that merges immersive storytelling with cutting-edge web3 mechanics, is teaming up with LAOS Network, the powerful Layer-1 blockchain solution designed to scale asset minting seamlessly across all chains. This partnership will open up new horizons for ARPG fans, offering a next-level gameplay experience that brings the thrill of games like Diablo and Path of Exile into the future of blockchain gaming.`,
    `In the evolving landscape of gaming, the introduction of ‘seasons’ has become a powerful tool to keep players engaged with fresh content, storylines, and gameplay. Netherak Demons continues to push these boundaries. While its core campaign, The Sons of Evil, centers on the epic conflict between humans and demons, players can also dive into standalone acts packed with rich lore and exclusive content, running parallel to the main story.`,
    `With the upcoming “Cursed Tome” act, Netherak Demons will implement LAOS’s innovative bridgeless minting technology to introduce “Tomes of Netherak” — valuable, tradable items that unlock exclusive access to new content. These tomes grant players access to unique quests and rewards spread across the vast Netherak locations, offering a deeper and more personalized adventure.`,
  ],
}
export default CaseStudyData;