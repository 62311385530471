/* eslint-disable react/prop-types */
import React from 'react';

const MenuItem = (props) => {
    return (
        <div className='pt-4 flex flex-row'>
            <div className="mr-8">
                <img src={props.icon} target={props.target} alt="menuicon" />
            </div> 
            <div className='flex flex-col hover:underline'>
                <a href={props.link} target={props.target} className="font-bold">{props.title}</a>
                <a href={props.link} target={props.target}>{props.desc}</a>
            </div>
        </div>
    )
}

const MenuDropdownDesktop = (props) => {
    const contents = props.contents
    return (
        <div className='block absolute mt-0 ml-0 min-w-[500px] w-max top-[calc(100%+20px)] right-0 rounded-b-xl
            bg-[linear-gradient(50deg,var(--tw-gradient-stops))] from-fvyellow via-20% via-fvorange to-70% to-fvpurple'>
            <div className="flex flex-row h-full">
                <div className="px-8 py-8">
                    <p className="font-bold mb-4">{contents.title}</p>
                    <p className="mb-4 max-w-[200px]">{contents.text}</p>
                    <a href={contents.link} className="text-fvyellow hover:underline">Learn more</a>
                    <img className="w-[140px] mx-auto mt-16" src={contents.image} alt="menuicon" />
                </div>
                <div className="px-8 pb-8 pt-4 bg-opacity-10 bg-slate-300 grow">
                    {contents.menu_items.map((item, i) => {
                        return (
                            <MenuItem 
                                key={i}
                                title={item.title}
                                desc={item.desc}
                                icon={item.icon}
                                link={item.link} 
                                target={item.target} />
                        )
                    }

                    )}
                </div>
            </div>
        </div>
    );
};

export default MenuDropdownDesktop;