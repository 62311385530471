/* eslint-disable react/prop-types */
import React from "react";
import { Helmet } from "react-helmet";

export default function CompatibilityRoadmap() {
  return (
    <>
      <Helmet>
        <link rel="stylesheet" href="/tailwind.min.css" />
        <link rel="stylesheet" href="/waitlist.css" />
        <style>
          {`
            .table-auto th, .table-auto td {
              background-color: rgba(255, 255, 255, 0);
              border: 1px solid #ddd;
              padding: 10px;
            }
            .table-auto th {
              background-color: rgba(97, 61, 147, 0.8); /* Assuming this is your brand color */
              color: #fff;
              padding: 15px;
            }
            .table-auto td:first-child {
              background-color: rgba(97, 61, 147, 0.8); /* Assuming this is your brand color */
              color: #fff;
            }
            .nested-list {
              margin-left: 2rem; /* Increased left margin for more padding */
            }
            .responsive-table {
              overflow-x: auto;
            }
          `}
        </style>
      </Helmet>
      <section className="lg:mt-[-128px] lg:pt-[128px] pt-10 sm:py-20 bg-cover bg-center sm:bg-[url('./img/roadmap_bg.jpg')]">
        <div className="container mx-auto px-10 lg:py-20">
          <div className="sm:w-2/3 grid grid-cols-1 place-items-center">
            <div className="col-span-2 text-left">
              <h1 className="pb-20 sm:pt-20 header-title text-4xl md:text-4xl leading-none lg:leading-tight">
                LAOS Network Wallet Compatibility Roadmap
              </h1>
            </div>
          </div>
        </div>
      </section>

      {/* <section
        style={{
          backgroundImage: `linear-gradient(144deg, #25143b 21.25%, #613d93 78.75%)`,
          padding: "0",
          marginTop: "0",
        }}
      >
         <CrowdloanComponent /> 
      </section> */}
      <div className="flex justify-center items-center">
        <section
          className="py-12 w-screen "
          style={{
            backgroundImage: `linear-gradient(144deg, #25143b 21.25%, #613d93 78.75%)`,
          }}
        >
          <div className=" px-10 xl:px-32 2xl:px-96">
            <p>
              LAOS Network is a substrate-based Parachain on Polkadot that will
              integrate full EVM compatibility.
              LAOS only uses one type of account, Ethereum accounts, also known
              as H160, based on ECDSA cryptography.
            </p>
            <br />
            <p>
              As detailed in the published roadmap, LAOS will integrate the EVM
              during Q3 2024. In Q4, LAOS plans to make other substrate
              functionalities like staking and vesting available via EVM calls.
              This roadmap reflects the functionalities that will be accessible
              to different types of wallets over time.
            </p>
            <br />
            <p>
              You can find more details about the steps following the TGE <a href="/roadmap-after-tge" className="text-fvyellow hover:text-fvyellow hover:underline">here</a>.
            </p>
            <br />
            <ul className="list-disc list-inside">
              <p className="brandColor">
                <strong>At TGE</strong>
              </p>{" "}
              <p>
                In the weeks following TGE, only the substrate side of the chain
                will be accessible, with staking as a key functionality. A few
                weeks after TGE, LAOS token transfers will also be enabled.
                During this phase, those looking at delegating, becoming
                collators, or transferring will need a wallet that supports both
                the Polkadot transaction format and Ethereum addresses. The{" "}
              </p>
              <a
                href="https://www.talisman.xyz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Talisman
              </a>{" "}
              wallet is the only example we are aware of that fulfills these
              criteria.
              <ul className="nested-list list-disc">
                <li>
                  The Ledger hardware wallet currently does not support using
                  Ethereum addresses to sign Polkadot transactions, making
                  staking with Ledger impossible at this stage.
                </li>
                <li>
                  Other Polkadot wallets like{" "}
                  <a
                    href="https://www.subwallet.app/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Subwallet
                  </a>
                  ,{" "}
                  <a
                    href="https://novawallet.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Nova Wallet
                  </a>
                  , or{" "}
                  <a
                    href="https://fearlesswallet.io/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Fearless Wallet
                  </a>{" "}
                  currently support the Polkadot API only via SS58
                  (non-Ethereum) accounts.
                </li>
                <li>
                  Please note that the polkadot.js in-browser wallet is an
                  available option, but is advised to be used solely for
                  testing purposes.
                </li>
              </ul>
              <p className="brandColor pt-4">
                <strong>After EVM integration (late Q3)</strong>
              </p>{" "}
              <p>
                When the full EVM is enabled on the LAOS Network, it will be
                possible to deploy and use smart contracts, as well as to
                transfer LAOS tokens, using any Ethereum compatible wallet,
                adding well-known wallets such as Metamask to the list already
                supported on TGE. These EVM functionalities will also be
                supported by any Ethereum-compatible hardware wallet, including
                Ledger.
              </p>
              <ul className="nested-list list-disc">
                <li>
                  Staking and vesting will still be accessible only via the
                  substrate side at this stage, hence requiring the wallets
                  described for the TGE phase. Vesting refers to the
                  transactions that are required to unlock tokens that have
                  already vested, and turn them into transferable tokens.
                </li>
              </ul>
              <p className="brandColor pt-4">
                <strong>
                  Staking and Vesting Precompile Integration (Q4).
                </strong>
              </p>{" "}
              <p>
                At this milestone, all main chain functions will be accessible by any
                Ethereum-compatible wallet.
              </p>  
            </ul>
            <br />
            <p className="brandColor pt-4">
                <strong>Summary</strong>
            </p>{" "}
            <br />
            <div className="responsive-table">
              <table className="table-auto w-full pt-4 text-center bg-transparent">
                <thead>
                  <tr>
                    <th>Functionality</th>
                    <th>
                      At TGE <br />
                    </th>
                    <th>
                      After Enabling Transfers <br />
                      (Q3)
                    </th>
                    <th>
                      After EVM Integration
                      <br /> (late Q3)
                    </th>
                    <th>
                      After Precompiles Integration
                      <br /> (Q4)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Staking</td>
                    <td>A</td>
                    <td>A</td>
                    <td>A</td>
                    <td>A & B</td>
                  </tr>
                  <tr>
                    <td>Vesting</td>
                    <td>A</td>
                    <td>A</td>
                    <td>A</td>
                    <td>A & B</td>
                  </tr>
                  <tr>
                    <td>Transfers</td>
                    <td>-</td>
                    <td>A</td>
                    <td>A & B</td>
                    <td>A & B</td>
                  </tr>
                  <tr>
                    <td>Smart Contract Deploy / Interaction</td>
                    <td>-</td>
                    <td>-</td>
                    <td>A & B</td>
                    <td>A & B</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <p>
              <strong>Wallets A (e.g. Talisman):</strong> Compatible with both Ethereum
              Addresses and the Polkadot API: currently, only Talisman, as far
              as we are aware. We will update this list as soon as we learn
              about relevant upgrades of other wallets.
            </p>
            <br />
            <p>
              <strong>Wallets B (e.g. Metamask):</strong> Compatible with the Ethereum API, such
              as Metamask, Ledger, Nova Wallet, Fearless Wallet, Subwallet, etc.
            </p>
          </div>
        </section>
      </div>
    </>
  );
}
